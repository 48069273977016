import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { persistor } from "../redux/store";
import { loginSuccess, logoutSuccess } from "../redux/reducers/userReducers ";
import { useDispatch } from "react-redux";
import showToast from "../components/Toast";
import { API_BASE_URL } from "../config";

// super to login

export const login = async (userData) => {
  return axios.post(`${API_BASE_URL}/auth/login`, userData);
};

export const useLogin = () => {
  const dispatch = useDispatch();
  return useMutation(login, {
    onSuccess: (data) => {
      dispatch(loginSuccess(data.data));
      if (data.data.user.role === "super") {
        showToast("User logged in Successfully!", "success");
      } else {
        showToast("User is Unauthorized!", "error");
      }
    },
  });
};

// super to logout

export const logout = async () => {
  return axios.get(`${API_BASE_URL}/auth/logout`);
};

export const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return useMutation(logout, {
    onSuccess: (data) => {
      dispatch(logoutSuccess());
      persistor.purge();
      navigate("/");
      showToast("User logout Successfully!", "success");
    },
  });
};

// get specific user
export const getUser = async (token, email) => {
  try {

    const response = await axios.get(`${API_BASE_URL}/super/user/?email=${email}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.data;
  } catch (error) {
    console.error("Error fetching user:", error.response?.data || error);
    throw error;
  }
};

export const useGetUser = (token, email) => {
  return useQuery(["user", email], () => getUser(token, email), {
    enabled: !!token && !!email, // Only fetch if token and email are provided
    onError: (error) => {
      console.error("Error fetching user:", error.message);
    },
  });
};

// get all users

const getUsers = async (
  token,
  search = "",
  type = "",
  sort = "",
  limit = "",
  page = 1
) => {
  return await axios.get(
    `${API_BASE_URL}/super/users?search=${search}&type=${type}&sort=${sort}&limit=${limit}&page=${page}`,
    {
      headers: {
        authorization: "Bearer " + token,
      },
    }
  );
};

export const useGetUsers = (token, search, type, sort, limit, page) => {
  return useQuery(["users", token, search, type, sort, limit, page], () =>
    getUsers(token, search, type, sort, limit, page)
  );
};

// get user details

const getUserDetails = async (userId, token) => {
  return axios.get(`${API_BASE_URL}/super/users/${userId}`, {
    headers: {
      authorization: "Bearer " + token,
    },
  });
};

export const useGetUserDetails = (userId, token) => {
  return useQuery(["user", userId, token], () => getUserDetails(userId, token));
};

// delete user

export const deleteUser = async (userData) => {
  return axios.delete(`${API_BASE_URL}/super/users/${userData.userId}`, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("users");
      queryClient.invalidateQueries("adminUsers");
      showToast("User deleted Sucessfully!", "success");
    },
  });
};

// update user

export const updateUser = async (userData) => {
  return axios.put(`${API_BASE_URL}/super/users/${userData.userId}`, userData, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation(updateUser, {
    onSuccess: (data) => {
      queryClient.resetQueries("user");
      showToast("User Updated Successfully!", "success");
    },
  });
};

// create user

export const createUser = async (userData) => {
  return axios.post(`${API_BASE_URL}/super/users`, userData, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useCreateUser = () => {
  const navigate = useNavigate();
  return useMutation(createUser, {
    onSuccess: (data) => {
      navigate("/users");
      showToast("User created Successfully!", "success");
    },
  });
};

// super to change password

export const changePassword = async (userData) => {
  return axios.post(`${API_BASE_URL}/auth/change-password`, userData, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useChangePassword = () => {
  return useMutation(changePassword, {
    onSuccess: (data) => {
      showToast("Password changed Successfully!", "success");
    },
  });
};

// update user image

export const updateUserImage = async (userData) => {
  return axios.put(
    `${API_BASE_URL}/super/update-user-image/${userData.userId}`,
    userData,
    {
      headers: {
        authorization: "Bearer " + userData.token,
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const useUpdateUserImage = () => {
  const queryClient = useQueryClient();
  return useMutation(updateUserImage, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("user");
      showToast("image uploaded Successfully!", "success");
    },
  });
};

// Send Invite

export const sendInvite = async (userData) => {
  return axios.post(`${API_BASE_URL}/super/send-invite`, userData, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useSendInvite = () => {
  return useMutation(sendInvite, {
    onSuccess: (data) => {
      showToast("Invite Sent Successfully!", "success");
    },
  });
};

// Generate Password

export const generatePassword = async (userData) => {
  return axios.put(`${API_BASE_URL}/auth/resetPassword`, userData);
};

export const useGeneratePassword = () => {
  return useMutation(generatePassword, {
    onSuccess: (data) => {
      showToast("Password Generated Successfully!", "success");
    },
  });
};

// Generate Otp

export const generateOtp = async (userData) => {
  return axios.post(`${API_BASE_URL}/auth/generateOtp`, userData);
};

export const useGenerateOtp = () => {
  return useMutation(generateOtp, {
    onSuccess: (data) => {
      showToast("OTP Generated Successfully!", "success");
    },
  });
};

// ------------------- get admin all users
const getAdminUsers = async (
  token,
  userId,
  search = "",
  type = "",
  sort = "",
  limit = "",
  page = 1
) => {
  return await axios.get(
    `${API_BASE_URL}/super/sub-users?id=${userId}&search=${search}&type=${type}&sort=${sort}&limit=${limit}&page=${page}`,
    {
      headers: {
        authorization: "Bearer " + token,
      },
    }
  );
};

export const useGetAdminUsers = (
  token,
  userId,
  search,
  type,
  sort,
  limit,
  page
) => {
  return useQuery(
    ["adminUsers", token, userId, search, type, sort, limit, page],
    () => getAdminUsers(token, userId, search, type, sort, limit, page)
  );
};

// create sub user

export const createSubUser = async (userData) => {
  return axios.post(`${API_BASE_URL}/super/sub-users`, userData, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useCreateSubUser = () => {
  const navigate = useNavigate();
  return useMutation(createSubUser, {
    onSuccess: (data) => {
      navigate(`/AdminUser/${data.data.user.createdBy}`);
      showToast("User created Successfully!", "success");
    },
  });
};

//---------------------------------------------- get dashboardmetric

const getMetrics = async (
  token,
  type = "foreman",
  startDate = "2023-01-01",
  endDate = "2024-01-01"
) => {
  return await axios.get(
    `${API_BASE_URL}/super/get-dashboard-metrics?type=${type}&startDate=${startDate}&endDate=${endDate}`,
    {
      headers: {
        authorization: "Bearer " + token,
      },
    }
  );
};

export const useGetMetrics = (token, type, startDate, endDate) => {
  return useQuery(["metric", token, type, startDate, endDate], () =>
    getMetrics(token, type, startDate, endDate)
  );
};

// Organization API Calls

// Create organization
export const createOrganization = async (orgData) => {
  return axios.post(`${API_BASE_URL}/super/organizations`, orgData, {
    headers: {
      authorization: "Bearer " + orgData.token,
    },
  });
};

export const useCreateOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation(createOrganization, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("organizations");
      showToast("Organization created successfully!", "success");
    },
  });
};

// Update organization
export const updateOrganization = async (orgData) => {
  return axios.put(`${API_BASE_URL}/super/organizations/${orgData.id}`, orgData, {
    headers: {
      authorization: "Bearer " + orgData.token,
    },
  });
};

export const useUpdateOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation(updateOrganization, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("organization");
      showToast("Organization updated successfully!", "success");
    },
  });
};

// Get organization details
const getOrganizationDetails = async (orgId, token) => {
  return axios.get(`${API_BASE_URL}/super/organizations/${orgId}`, {
    headers: {
      authorization: "Bearer " + token,
    },
  });
};

export const useGetOrganizationDetails = (orgId, token) => {
  return useQuery(["organization", orgId, token], () => getOrganizationDetails(orgId, token));
};

// Get all organizations
const getOrganizations = async (
  token,
  search = "",
  sort = "",
  limit = "",
  page = 1
) => {
  return await axios.get(
    `${API_BASE_URL}/super/organizations?search=${search}&sort=${sort}&limit=${limit}&page=${page}`,
    {
      headers: {
        authorization: "Bearer " + token,
      },
    }
  );
};

// Get a single organization by ID
const getOrganization = async (organizationId, token) => {
  return await axios.get(`${API_BASE_URL}/organizations/${organizationId}`, {
    headers: {
      authorization: "Bearer " + token,
    },
  });
};

export const useGetOrganization = (organizationId, token) => {
  return useQuery(["organization", organizationId, token], () =>
    getOrganization(organizationId, token)
  );
};

export const useGetOrganizations = (token, search, sort, limit, page) => {
  return useQuery(["organizations", token, search, sort, limit, page], () =>
    getOrganizations(token, search, sort, limit, page)
  );
};

// Delete organization
export const deleteOrganization = async (orgData) => {
  return axios.delete(`${API_BASE_URL}/super/organizations/${orgData.id}`, {
    headers: {
      authorization: "Bearer " + orgData.token,
    },
  });
};

export const useDeleteOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteOrganization, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("organizations");
      showToast("Organization deleted successfully!", "success");
    },
  });
};
